import { useScroll } from "@vueuse/core";

export const useScrollMemory = function (containerRef: Ref<HTMLElement | undefined>) {
  const { x, y } = useScroll(containerRef);

  const scrollPosX = ref(0);
  const scrollPosY = ref(0);

  watch([x, y], () => {
    scrollPosX.value = x.value;
    scrollPosY.value = y.value;
  });

  onActivated(() => {
    x.value = scrollPosX.value;
    y.value = scrollPosY.value;
  });
};
